@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply underline underline-offset-2 decoration-1;
  }

  a:hover {
    @apply decoration-2;
  }

  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  [type='checkbox']:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  [type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
  }

  input[type='radio'] {
    filter: grayscale(0.5) hue-rotate(10deg);
    transform: scale(1.8, 1.8);
    -moz-transform: scale(1.8, 1.8);
    -ms-transform: scale(1.8, 1.8);
    -webkit-transform: scale(1.8, 1.8);
    -o-transform: scale(1.8, 1.8);
  }
}

@layer utilities {
  .placeholder-normal::placeholder {
    @apply font-normal text-sm md:text-base;
  }
}

.action ol {
  counter-reset: item;
}

.action li {
  counter-increment: item;
}

.action li:before {
  @apply bg-primary;
  margin-right: 10px;
  content: counter(item);
  border-radius: 100%;
  color: white;
  width: 1.2em;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-align: center;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}

section a,
aside a {
  @apply text-primary;
}

section p {
  @apply max-w-[65ch] mb-6;
}

main {
  @apply pt-8 text-slate-800;
}

section h2 {
  @apply pt-2 mt-16 mb-6 text-xl font-bold tracking-tight border-t-4 sm:text-2xl border-primary;
}

section h2:first-child {
  @apply mt-0;
}

main section {
  @apply mt-16;
}

main section:first-child {
  @apply mt-0;
}

#toc .active {
  @apply border-primary bg-slate-100 text-black;
}

@keyframes updown {
  0% {
    transform: translateY(8px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-8px);
  }
}

aside {
  @apply -mx-4 sm:mx-0;
}

aside ul {
  @apply ml-5;
}

main section ul {
  @apply list-disc;
}

main section ol {
  @apply mb-4 sm:ml-8;
}

main section ol li {
  counter-increment: step-counter;
  @apply pb-8 my-0 pl-9 pt-0.5 relative;
}

main section ol li p {
  @apply mb-4;
}

main section ol li::before {
  content: counter(step-counter);
  @apply rounded-full bg-primary ring-4 ring-white text-white text-base font-bold flex items-center justify-center w-8 h-8 absolute left-0 top-0;
}

main section ol li.completed::before {
  content: '\2713';
  @apply bg-green-700;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

main section li {
  @apply mb-4;
}

main section ul {
  @apply -mt-2 mb-4 ml-8 sm:ml-12;
}

main section ul ul,
main section ol ol {
  @apply mt-2;
}

.search-results em {
  @apply bg-yellow-200 not-italic;
}

.dark-bg,
.dark-bg main,
.dark-bg .feedback-container {
  @apply bg-slate-200;
}

.grecaptcha-badge {
  visibility: hidden;
}

.sigCanvas {
  width: 100%;
  height: 200px;
}

section.rendered-block table tr {
  @apply even:bg-slate-100;
}

section.rendered-block table td {
  @apply text-xs p-1;
}
